import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
export default function useProduct() {
  const router = useRouter();

  const getProducts = async (page) => {
    return await ApiService.query("market_saiyut_products",page);
  };

  const getProduct = async (id) => {
    return await ApiService.get("market_saiyut_products/" + id);

  };

  const createProduct = async () => {
    return await ApiService.get("market_saiyut_product_creates");
  };

  const destroyProduct = async (id) => {
    await ApiService.delete("market_saiyut_products/" + id);
  };

  const stoerProduct = async (data) => {
    await ApiService.post("market_saiyut_products", data);
    await router.push({ name: "apps.travels.market_saiyut.product.index" });
  };

  const updateProduct = async (data) => {
    await ApiService.put("market_saiyut_products/" + data.id, data);
    await router.push({
      name: "apps.travels.market_saiyut.product.edit",
      params: { id: data.id },
    });
  };

  const getProductImage = async (id) => {
    return await ApiService.get("market_saiyut_product_get_image/" + id);

  };

  const updateProductImage = async (data) => {
    return ApiService.post("market_saiyut_product_image", data);
    
  };

  const destroyProductImage = async (id) => {
    await ApiService.delete("market_saiyut_product_image/" + id);
  };



  

  return {
    getProducts,
    getProduct,
    createProduct,
    destroyProduct,
    stoerProduct,
    updateProduct,
    getProductImage,
    updateProductImage,
    destroyProductImage
  };
}
